import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { ContentComponent } from "./shared/components/layout/content/content.component";
import { FullComponent } from "./shared/components/layout/full/full.component";
import { ResetPasswordComponent} from "./auth/reset-password/reset-password.component"
import { ForgotPswdComponent } from './auth/forgot-pswd/forgot-pswd.component';
// import { full } from "./shared/routes/full.routes";
import { content } from "./shared/routes/routes";

import { AdminGuard } from './shared/guard/admin.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [AdminGuard],
    data: { roles: [0,2,6]},
    redirectTo: 'auth/login',
    pathMatch: 'full'
  },
  {
    path: 'auth/login',
    // canActivate: [AdminGuard],
    component: LoginComponent
  },
  {
    path: 'reset-password/:token',
    component: ResetPasswordComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPswdComponent
  },
  {
    path: 'dash',
    component: LoginComponent
  },
  {
    path: '',
    component: ContentComponent,
    canActivate: [AdminGuard],
    data:{roles:[0,2,6]},
    loadChildren: () => import('../app/components/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'emails/:id',
    canActivate: [AdminGuard],
    component: ContentComponent,
    loadChildren: () => import('../app/emails/emails.module').then(m => m.EmailsModule)
  },
  {
    path:'',
    canActivate: [AdminGuard],
    component:ContentComponent,
    loadChildren:() => import('../app/users/users.module').then(m => m.UsersModule)
  },
  {
    path:'',
    canActivate: [AdminGuard],
    component:ContentComponent,
    data:{roles:[0,1,2,3,4,6]},
    loadChildren:() => import('../app/customer/customer.module').then(m => m.CustomerModule)
  },
  {
    path:'',
    canActivate: [AdminGuard],
    component:ContentComponent,
    data:{roles:[0,1,2,3,4,6]},
    loadChildren:() => import('../app/supplier/supplier.module').then(m => m.SupplierModule)
  },

  {
    path:'',
    canActivate: [AdminGuard],
    component:ContentComponent,
    data:{roles:[0,1,2,3,4,6]},
    loadChildren:() => import('../app/subcontractors/subcontractors.module').then(m => m.SubcontractorsModule)
  },

  {
    path:'',
    canActivate: [AdminGuard],
    component:ContentComponent,
    data:{roles:[0,1,2,3,4,6]},
    loadChildren:() => import('../app/miscellaneous/miscellaneous.module').then(m => m.MiscellaneousModule)
  },

  {
    path:'',
    canActivate: [AdminGuard],
    component:ContentComponent,
    loadChildren:() => import('../app/settings/settings.module').then(m => m.SettingsModule)
  },
  {
    path:'',
    canActivate: [AdminGuard],
    component:ContentComponent,
    loadChildren:() => import('../app/project/project.module').then(m => m.ProjectModule)
  },
  {
    path:'',
    canActivate: [AdminGuard],
    component:ContentComponent,
    loadChildren:() => import('../app/claims/claims.module').then(m => m.ClaimsModule)
  },

  {
    path:'',
    canActivate: [AdminGuard],
    component:ContentComponent,
    loadChildren:() => import('../app/summary/summary.module').then(m => m.SummaryModule)
  },

  {
    path:'',
    canActivate: [AdminGuard],
    component:ContentComponent,
    loadChildren:() => import('../app/outside-communication/outside-communication.module').then(m => m.OutsideCommunicationModule)
  },

  {
    path: '',
    component: FullComponent,
    // canActivate: [AdminGuard],
    // children: full
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [[RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled'
  })],
],
  exports: [RouterModule]
})
export class AppRoutingModule { }
