<!-- Page Header Start-->
<div class="page-main-header" [class.close_icon]="navServices.collapseSidebar">
  <div class="main-header-right row m-0">
    <form class="form-inline search-full" [class.open]="search">
      <div class="form-group w-100">
        <input class="form-control-plaintext" [(ngModel)]="text" [ngModelOptions]="{standalone: true}"
          (keyup)="searchTerm(text)" type="text" autocomplete="off" placeholder="Search..">
        <app-feather-icons [icon]="'x'" class="close-search" (click)="searchToggle()"></app-feather-icons>
        <div class="Typeahead-menu" [class.is-open]="searchResult" *ngIf="menuItems?.length" id="search-outer">
          <div class="ProfileCard u-cf" *ngFor="let menuItem of menuItems | slice:0:8">
            <div class="ProfileCard-avatar">
              <app-feather-icons [icon]="menuItem.icon" class="header-search"></app-feather-icons>
            </div>
            <div class="ProfileCard-details">
              <div class="ProfileCard-realName">
                <a [routerLink]="menuItem?.path" class="realname" (click)="removeFix()">{{menuItem?.title}}</a>
              </div>
            </div>
          </div>
        </div>
        <div class="Typeahead-menu" [class.is-open]="searchResultEmpty">
          <div class="tt-dataset tt-dataset-0">
            <div class="EmptyMessage">
              Opps!! There are no result found.
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="main-header-left">
      <div class="logo-wrapper">
        <a routerLink='/'>
          <img class="img-fluid for-light" src="assets/images/logo/logo.png" alt="">
          <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="">
        </a>
      </div>
      <div class="toggle-sidebar" (click)="sidebarToggle()">
        <app-feather-icons [icon]="'grid'" class="status_toggle middle"></app-feather-icons>
      </div>
    </div>
    <div class="left-menu-header col horizontal-wrapper pl-0">
    </div>
    <div class="nav-right col-8 pull-right right-menu">
      <ul class="nav-menus">
        <li class="profile-nav onhover-dropdown p-0">
          <div class="media profile-media">
            <img class="b-r-10" src="assets/images/user/user.png" alt="">
            <div class="media-body"><span>{{username}} {{lastName}}</span>
              <p class="mb-0 font-roboto">{{userole}} <i class="middle fa fa-angle-down"></i></p>
            </div>
          </div>
          <ul class="profile-dropdown onhover-show-div">
            <li routerLink="/profile-settings">
              <app-feather-icons [icon]="'settings'"></app-feather-icons><span>Settings</span>
            </li>
            <li (click)="statuschange()">
              <app-feather-icons [icon]="'log-out'"></app-feather-icons>Logout
            </li>
            <!-- <ng-template #logout>
              <div class="modal-body text-center">
                <form class="form theme-form">
                  <div class="icon">
                    <i class="fa fa-exclamation-circle fa-4x text-warning  " aria-hidden="true"></i>
                  </div>
                  <div class="form-group row">
                    <div class="col-sm-12 text-center ">
                      <div class="mt-2">
                        <h4 class=" mb-2">Are you leaving? </h4>
                        <p>Are you sure want to logout?.All your <br> unsaved data will be lost.</p>
                      </div>

                    </div>
                  </div>

                </form>
              </div>
              <div class="row mb-3">
                <div class="col-sm-6">
                  <button type="button" class="btn btncolor pull-right btn-sm " routerLink="/auth/login">Yes</button>
                </div>
                <div class="col-sm-6 ">
                  <button type="button" class="btn btn-danger btn-sm ">Cancel</button>
                </div>
              </div>
            </ng-template> -->
          </ul>
        </li>
      </ul>
    </div>
  </div>
</div>
<!-- Page Header Ends -->
