import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
const env_datas = environment.firebase;
const CALL_API_URL = env_datas.base_url;
@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private issuer = {
    login: `${CALL_API_URL}api/auth/login`,
    register: `${CALL_API_URL}api/auth/register`
  }
  constructor() { }
  handleData(token){
    localStorage.setItem('auth_token',token);
  }
  getToken(){
    return localStorage.getItem('auth_token');
  }
    // Verify the token
    isValidToken(){
      const token = this.getToken();

      if(token){
        const payload = this.payload(token);
        if(payload){
          return Object.values(this.issuer).indexOf(payload.iss) > -1 ? true : false;
        }
      } else {
         return false;
      }
   }

   payload(token) {
    const jwtPayload = token.split('.')[1];
    return JSON.parse(atob(jwtPayload));
  }
    // User state based on valid token
    isLoggedIn() {
      return this.isValidToken();
    }
      // Remove token
  removeToken(){
    localStorage.removeItem('auth_token');
  }

}
