<main class="d-flex align-items-center min-vh-100 py-3 py-md-0">
  <div class="container">
    <div class="col-lg-6 offset-lg-3">
      <div class="card login-card">
        <div class="row no-gutters">
          <!-- <div class="col-md-5">
            <img src="assets/images/Backgrounds/bhmclogin.png" alt="login" class="login-card-img">
          </div> -->
          <div class="col-lg-12">
            <div class="card-body">

              <form class="theme-forms theme-form" [formGroup]="loginForm">

                <div class="col-sm-10 offset-sm-1">
                  <div class="brand-wrapper text-center">
                    <img src="assets/images/logo/logo.png" alt="logo" class="logo">
                  </div>
                  <h6 class="login-card-description text-center">LOGIN</h6>
                  <div class="form-group row">
                    <div class="col-sm-12">
                      <label for="email" class="control-label">Email address</label>
                      <input type="email" name="email" id="email" class="form-control" formControlName="email"
                        placeholder="Email address">
                      <div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.required"
                        class="text text-danger mt-1">
                        Email address should not be blank.
                      </div>
                      <div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.email"
                        class="text text-danger mt-1">
                        Invalid email format.
                      </div>
                      <div *ngIf="isSubmit &&  loginForm.controls.email.errors?.backenderrors" class="text-danger">
                        {{(loginForm.controls.email.errors.errmsg)?loginForm.controls.email.errors.errmsg:'This email id
                        is invalid!'}}</div>
                    </div>
                  </div>
                  <div class="row form-group mb-4">
                    <div class="col-sm-12">
                      <label for="password" class="control-label">Password</label>
                      <div class="input-group">
                        <input type="password" id="password" #password1 class="form-control" placeholder="Password"
                          formControlName="password">
                        <div class="input-group-append" (click)="changeType(password1, 1)">
                          <span class="input-group-text"><i [ngClass]="toggle1 ? 'fa fa-eye' : 'fa fa-eye-slash'"
                              style="font-size: 11px;"></i></span>
                        </div>
                      </div>
                      <div *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required"
                        class="text text-danger mt-1">
                        Password should not be blank.
                      </div>
                      <div *ngIf="isSubmit && loginForm.controls.password.errors?.backenderrors" class="text-danger">
                        {{(loginForm.controls.password.errors.errmsg)?loginForm.controls.password.errors.errmsg:'This
                        password is invalid!'}}</div>
                    </div>
                  </div>
                  <div class="form-group row check">
                    <div class="col-sm-12">
                      <label class="control-label" for="chk-ani">
                        <input class="checkbox_animated" id="" type="checkbox" formControlName="remember">Remember me
                      </label>
                    </div>
                  </div>
                  <div class="text-center">
                    <!-- <button class="btn btn-sm  login-btn mb-4"
                      type="submit"><span>Login</span>
                    </button> -->
                    <button class="btn btn-sm  login-btn mb-4" type="submit"
                      (click)="login(loginForm.value)"><span>Login</span>
                    </button>
                  </div>
                  <div class="row form-group">
                    <div class="col-sm-12" style="align-self: center;">
                      <a routerLink="/forgot-password" class="forgot-password-link">Forgot password?</a>
                    </div>
                  </div>
                </div>

              </form>

            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</main>