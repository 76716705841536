// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase: {
    apiKey: 'Your Api Key',
    authDomain: 'Your Auth Domain',
    databaseURL: 'Your Database Url',
    projectId: 'Your Project Id',
    storageBucket: 'Your StorageBucket url',
    messagingSenderId: 'Your Sender Id',

    // base_url: 'http://127.0.0.1:8000/',
    // api_url: 'http://127.0.0.1:8000/api/',
    // app_url: 'http://localhost:4200',
    // image_url: 'http://127.0.0.1:8000/uploads/',
    // oauthsignin: 'http://localhost:8000/api/',

    // base_url: 'https://ishtech.com.au/airmechlaravel/',
    // api_url: 'https://ishtech.com.au/airmechlaravel/api/',
    // image_url: 'https://ishtech.com.au/airmechlaravel/uploads/',
    // app_url: 'https://ishtech.com.au/airmech',
    // oauthsignin: 'https://ishtech.com.au/airmechlaravel/api/',

    /* Hide  <app-devop-alert></app-devop-alert> this in app.component.html before build live*/

    // base_url: 'https://ishtech.com.au/airmechlaravelprod/',
    // api_url: 'https://ishtech.com.au/airmechlaravelprod/api/',
    // image_url: 'https://ishtech.com.au/airmechlaravelprod/uploads/',
    // app_url: 'https://ishtech.com.au/airmechprod',
    // oauthsignin: 'https://ishtech.com.au/airmechlaravelprod/api/',

    // base_url: 'https://software.airmech.com.au/airmech/',
    // api_url: 'https://software.airmech.com.au/airmech/api/',
    // image_url: 'https://software.airmech.com.au/airmech/uploads/',
    // app_url: 'https://software.airmech.com.au',
    // oauthsignin: 'https://software.airmech.com.au/airmech/api/',

    base_url: 'https://airmechsoftware.com.au/airmech/',
    api_url: 'https://airmechsoftware.com.au/airmech/api/',
    image_url: 'https://airmechsoftware.com.au/airmech/uploads/',
    app_url: 'https://airmechsoftware.com.au/',
    oauthsignin: 'https://airmechsoftware.com.au/airmech/api/',
  },

  // base_url: 'http://localhost:8000/api/saveUser'
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
