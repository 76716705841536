import { Injectable } from '@angular/core';
import { HttpClient  } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

const env_datas = environment.firebase;
const CALL_API_URL = env_datas.api_url;

// User interface
export class User {
  name: String;
  email: String;
  password: String;
  password_confirmation: String
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor( private http:HttpClient) { }
   // Login
 signin(user: User): Observable<any> {
  return this.http.post<any>(`${CALL_API_URL}login`, user);
}

forgotPassword(data) {
  return this.http.post(`${CALL_API_URL}forgotpswd`, data)
}
resetPassword(data) {
  return this.http.post(`${CALL_API_URL}change-password`, data)
}
}
