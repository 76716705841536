<!-- <div class="customizer-links" [ngClass]="{'open' : customizer }" id="inner-customizer">
   <div class="nav flex-column nac-pills" id="c-pills-tab" role="tablist" aria-orientation="vertical">
      <a href="javascript:void(0)" class="nav-link" [ngClass]="{'active show': customizer }" id="c-pills-home-tab"
         (click)="Customizer()">
         <div class="settings"> <i class="icon-settings"></i> </div>
      </a>
   </div>
</div> -->
<div class="customizer-contain" [ngClass]="{'open' : customizer }">
   <div class="tab-content" id="c-pills-tabContent">
      <div class="customizer-header">
         <i class="icofont-close icon-close" (click)="Customizer()"></i>
         <h5>Customizer</h5>
         <p class="mb-0">Customize &amp; Preview Real Time</p>
         <button class="btn btn-primary plus-popup mt-2" (click)="openModal(popup)">Configuration</button>
         <ng-template #popup let-modal>
            <div class="modal-header modal-copy-header">
               <h5 class="headerTitle mb-0">Customizer configuration</h5>
               <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
               </button>
            </div>
            <div class="modal-body">
               <div class="modal-header config-popup">
                  <p>To replace our design with your desired theme. Please do configuration as mention </p>
                  <p> <b> Path : src > app > shared > services > layout.service.ts </b> </p>
                  <div>
                     <pre>
<code>
      public config = &#123;
         settings&#58; &#123;
            layout_type&#58; '{{layout.config.settings.layout_type}}',
            sidebar&#58; &#123;
               type&#58; '{{layout.config.settings.sidebar.type}}',
               body_type&#58; '{{layout.config.settings.sidebar.body_type}}'
            &#125;,
            sidebar_setting&#58; '{{layout.config.settings.sidebar_setting}}'
         &#125;,
         color&#58; &#123;
            color&#58; '{{layout.config.color.color}}',
            primary_color&#58; '{{layout.config.color.primary_color}}',
            secondary_color&#58; '{{layout.config.color.secondary_color}}',
            mix_layout&#58; '{{layout.config.color.mix_background_layout}}'
         &#125;
      &#125;
</code>
                  </pre>
                  </div>
               </div>
            </div>
         </ng-template>
      </div>
      <div class="customizer-body custom-scrollbar">
         <div class="tab-pane fade" [ngClass]="{'active show': customizer }" id="c-pills-home" role="tabpanel"
            aria-labelledby="c-pills-home-tab">
            <h6>Layout Type</h6>
            <ul class="main-layout layout-grid">
               <li data-attr="ltr" [ngClass]="{'active': layoutType == 'ltr'}" (click)="customizeLayoutType('ltr')">
                  <div class="header bg-light">
                     <ul>
                        <li></li>
                        <li></li>
                        <li></li>
                     </ul>
                  </div>
                  <div class="body">
                     <ul>
                        <li class="bg-light sidebar"></li>
                        <li class="bg-light body"><span class="badge badge-primary">LTR</span></li>
                     </ul>
                  </div>
               </li>
               <li data-attr="rtl" [ngClass]="{'active': layoutType == 'rtl'}" (click)="customizeLayoutType('rtl')">
                  <div class="header bg-light">
                     <ul>
                        <li></li>
                        <li></li>
                        <li></li>
                     </ul>
                  </div>
                  <div class="body">
                     <ul>
                        <li class="bg-light body"><span class="badge badge-primary">RTL</span></li>
                        <li class="bg-light sidebar"></li>
                     </ul>
                  </div>
               </li>
               <li data-attr="Box" class='box-layout' [ngClass]="{'active': layoutType == 'box-layout'}"
                  (click)="customizeLayoutType('box-layout')">
                  <div class="header bg-light">
                     <ul>
                        <li></li>
                        <li></li>
                        <li></li>
                     </ul>
                  </div>
                  <div class="body">
                     <ul>
                        <li class="bg-light sidebar"></li>
                        <li class="bg-light body"> <span class="badge badge-primary">BOX</span> </li>
                     </ul>
                  </div>
               </li>
            </ul>
            <h6>Sidebar Type</h6>
            <ul class="sidebar-type layout-grid">
               <li data-attr="normal-sidebar" [class.d-none]="screenwidth < 991"
                  [ngClass]="{'active': sidebarType == 'horizontal-wrapper'}"
                  (click)="customizeSidebarType('horizontal-wrapper')">
                  <div class="header bg-light">
                     <ul>
                        <li></li>
                        <li></li>
                        <li></li>
                     </ul>
                  </div>
                  <div class="body">
                     <ul>
                        <li class="bg-dark sidebar"></li>
                        <li class="bg-light body"> </li>
                     </ul>
                  </div>
               </li>
               <li data-attr="compact-sidebar" [ngClass]="{'active': sidebarType == 'compact-wrapper'}"
                  (click)="customizeSidebarType('compact-wrapper')">
                  <div class="header bg-light">
                     <ul>
                        <li></li>
                        <li></li>
                        <li></li>
                     </ul>
                  </div>
                  <div class="body">
                     <ul>
                        <li class="bg-dark sidebar compact"></li>
                        <li class="bg-light body"></li>
                     </ul>
                  </div>
               </li>
            </ul>
            <h6>Sidebar settings</h6>
            <ul class="sidebar-setting layout-grid">
               <li data-attr="default-sidebar" [ngClass]="{'active': sidebarSetting == 'default-sidebar'}"
                  (click)="customizeSidebarSetting('default-sidebar')">
                  <div class="header bg-light">
                     <ul>
                        <li></li>
                        <li></li>
                        <li></li>
                     </ul>
                  </div>
                  <div class="body bg-light"> <span class="badge badge-primary">Default</span> </div>
               </li>
               <li data-attr="border-sidebar" [ngClass]="{'active': sidebarSetting == 'border-sidebar'}"
                  (click)="customizeSidebarSetting('border-sidebar')">
                  <div class="header bg-light">
                     <ul>
                        <li></li>
                        <li></li>
                        <li></li>
                     </ul>
                  </div>
                  <div class="body bg-light"> <span class="badge badge-primary">Border</span> </div>
               </li>
               <li data-attr="iconcolor-sidebar" [ngClass]="{'active': sidebarSetting == 'iconcolor-sidebar'}"
                  (click)="customizeSidebarSetting('iconcolor-sidebar')">
                  <div class="header bg-light">
                     <ul>
                        <li></li>
                        <li></li>
                        <li></li>
                     </ul>
                  </div>
                  <div class="body bg-light"> <span class="badge badge-primary">icon Color</span> </div>
               </li>
            </ul>
            <h6>Light layout</h6>
             <ul class="layout-grid customizer-color">
                <li class="color-layout" data-attr="color-1" data-primary="#7366ff" data-secondary="#f73164"
                  [ngClass]="{'active': lightColorScheme == 'color-1'}" (click)="customizeLightColorScheme('color-1')">
                   <div></div>
                </li>
                <li class="color-layout" data-attr="color-2" data-primary="#4831D4" data-secondary="#ea2087"
                  [ngClass]="{'active': lightColorScheme == 'color-2'}" (click)="customizeLightColorScheme('color-2')">
                   <div></div>
                </li>
                <li class="color-layout" data-attr="color-3" data-primary="#d64dcf" data-secondary="#8e24aa"
                  [ngClass]="{'active': lightColorScheme == 'color-3'}" (click)="customizeLightColorScheme('color-3')">
                   <div></div>
                </li>
                <li class="color-layout" data-attr="color-4" data-primary="#4c2fbf" data-secondary="#2e9de4"
                  [ngClass]="{'active': lightColorScheme == 'color-4'}" (click)="customizeLightColorScheme('color-4')">
                   <div></div>
                </li>
                <li class="color-layout" data-attr="color-5" data-primary="#7c4dff" data-secondary="#7b1fa2"
                  [ngClass]="{'active': lightColorScheme == 'color-3'}" (click)="customizeLightColorScheme('color-5')">
                   <div></div>
                </li>
                <li class="color-layout" data-attr="color-6" data-primary="#3949ab" data-secondary="#4fc3f7"
                  [ngClass]="{'active': lightColorScheme == 'color-3'}" (click)="customizeLightColorScheme('color-6')">
                   <div></div>
                </li>
             </ul>
             <h6 class="">Dark Layout</h6>
             <ul class="layout-grid customizer-color dark">
                <li class="color-layout" data-attr="color-1" data-primary="#4466f2" data-secondary="#1ea6ec"
                  [ngClass]="{'active': darkColorScheme == 'color-1'}" (click)="customizeDarkColorScheme('color-1')">
                   <div></div>
                </li>
                <li class="color-layout" data-attr="color-2" data-primary="#4831D4" data-secondary="#ea2087"
                  [ngClass]="{'active': darkColorScheme == 'color-1'}" (click)="customizeDarkColorScheme('color-2')">
                   <div></div>
                </li>
                <li class="color-layout" data-attr="color-3" data-primary="#d64dcf" data-secondary="#8e24aa"
                  [ngClass]="{'active': darkColorScheme == 'color-1'}" (click)="customizeDarkColorScheme('color-3')">
                   <div></div>
                </li>
                <li class="color-layout" data-attr="color-4" data-primary="#4c2fbf" data-secondary="#2e9de4"
                  [ngClass]="{'active': darkColorScheme == 'color-1'}" (click)="customizeDarkColorScheme('color-4')">
                   <div></div>
                </li>
                <li class="color-layout" data-attr="color-5" data-primary="#7c4dff" data-secondary="#7b1fa2"
                  [ngClass]="{'active': darkColorScheme == 'color-1'}" (click)="customizeDarkColorScheme('color-5')">
                   <div></div>
                </li>
                <li class="color-layout" data-attr="color-6" data-primary="#3949ab" data-secondary="#4fc3f7"
                  [ngClass]="{'active': darkColorScheme == 'color-1'}" (click)="customizeDarkColorScheme('color-6')">
                   <div></div>
                </li>
             </ul>
             <h6>Mix Layout</h6>
            <ul class="layout-grid customizer-mix">
               <li class="color-layout" data-attr="default" [ngClass]="{'active': MIXLayout == 'default'}"
                  (click)="customizeMixLayout('default')">
                  <div class="header bg-light">
                     <ul>
                        <li></li>
                        <li></li>
                        <li></li>
                     </ul>
                  </div>
                  <div class="body">
                     <ul>
                        <li class="bg-light sidebar"></li>
                        <li class="bg-light body"> </li>
                     </ul>
                  </div>
               </li>
               <li class="color-layout" data-attr="dark-sidebar" [ngClass]="{'active': MIXLayout == 'dark-sidebar'}"
                  (click)="customizeMixLayout('dark-sidebar')">
                  <div class="header bg-light">
                     <ul>
                        <li></li>
                        <li></li>
                        <li></li>
                     </ul>
                  </div>
                  <div class="body">
                     <ul>
                        <li class="bg-dark sidebar"></li>
                        <li class="bg-light body"> </li>
                     </ul>
                  </div>
               </li>
               <li class="color-layout" data-attr="dark-only" [ngClass]="{'active': MIXLayout == 'dark-only'}"
                  (click)="customizeMixLayout('dark-only')">
                  <div class="header bg-dark">
                     <ul>
                        <li></li>
                        <li></li>
                        <li></li>
                     </ul>
                  </div>
                  <div class="body">
                     <ul>
                        <li class="bg-dark sidebar"></li>
                        <li class="bg-dark body"> </li>
                     </ul>
                  </div>
               </li>
            </ul>
         </div>
      </div>
   </div>
</div>