import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AuthService } from '../../shared/auth/auth.service';
import { ToastrService } from 'ngx-toastr';


type UserFields = 'email' | 'password';
type FormErrors = { [u in UserFields]: string };

@Component({
  selector: 'app-forgot-pswd',
  templateUrl: './forgot-pswd.component.html',
  styleUrls: ['./forgot-pswd.component.scss']
})
export class ForgotPswdComponent implements OnInit {

  public forgotForm: FormGroup;
  formSubmit: boolean = false;


  constructor(public authService: AuthService, private fb: FormBuilder, private toaster: ToastrService,
    private route: ActivatedRoute, public router: Router) {
    const url = this.route.snapshot.url;
    this.forgotForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  ngOnInit() {
  }

  login(l) {
    this.router.navigate(['dashboard']);
  }
  onForgotFormSubmit() {
    this.formSubmit = true;
    if (this.forgotForm.valid) {
      this.authService.forgotPassword(this.forgotForm.value).subscribe(
        (result: any) => {
          let msg = (result.message) ? result.message : 'Password reset request successfully.Please check your mail for reset your password.';
          this.toaster.success(msg);
          this.formSubmit = false;
          this.forgotForm.reset();
          this.router.navigate(['auth/login']);
        }, (err) => {
          let errorcode = (err.error.errorcode) ?? 0;
          let errormsg = (err.error.message) ?? 'Failed to password change.';
          if (errorcode == 1) {
            this.forgotForm.controls.email.setErrors({ 'backenderrors': true, 'errmsg': errormsg },);
          } else {
            this.toaster.warning(errormsg);
          }
        })
    }
  }

}
