import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
const headers = new HttpHeaders();
headers.append('Content-Type', 'multipart/form-data');
headers.append('Accept', 'application/json');
const env_datas = environment.firebase;
const CALL_API_URL = env_datas.base_url;

@Injectable({
  providedIn: 'root',
})
export class DataServiceService {
  constructor(private http: HttpClient) { }
  isAnimationDone: boolean = true;

  saveCategory(data) {
    return this.http.post(`${CALL_API_URL}api/storeCategory`, data);
  }

  getCategory() {
    return this.http.get(`${CALL_API_URL}api/getCategory`);
  }

  statuschange(data) {
    return this.http.post(`${CALL_API_URL}api/category/statuschange`, data);
  }
  passwordchange(data) {
    return this.http.post(`${CALL_API_URL}api/passwordChange`, data);
  }
  updateCategory(id, data) {
    return this.http.post(`${CALL_API_URL}api/updateCategory/` + id, data);
  }
  updateProfile(userid, data) {
    return this.http.post(`${CALL_API_URL}api/updateProfile/` + userid, data);
  }
  // updateSig(data) {
  //   return this.http.post(`${CALL_API_URL}api/updateSig`, data);
  // }
  updateSig(myFormData: FormData) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.http.post(`${CALL_API_URL}api/updateSig`, myFormData, { headers: headers });
  }
  getActivecategories() {
    return this.http.get(`${CALL_API_URL}api/getAllcategories/`);
  }

  getAllcategorytypes() {
    return this.http.get(`${CALL_API_URL}api/getAllcategorytypes/`);
  }

  saveCategorytype(data) {
    return this.http.post(`${CALL_API_URL}api/storeCategorytype`, data);
  }

  updateCategorytype(id, data) {
    return this.http.post(`${CALL_API_URL}api/updateCategorytype/` + id, data);
  }

  typestatuschange(data) {
    return this.http.post(`${CALL_API_URL}api/typestatueschange`, data);
  }

  getAllactivecategories() {
    return this.http.get(`${CALL_API_URL}api/getAllactivecategories/`);
  }

  getAllactivetypes() {
    return this.http.get(`${CALL_API_URL}api/getAllactivetypes/`);
  }

  saveEquipment(data) {
    return this.http.post(`${CALL_API_URL}api/saveEquipment`, data);
  }

  getAllequipment() {
    return this.http.get(`${CALL_API_URL}api/getAllequipments/`);
  }

  equipmentStatuschange(data) {
    return this.http.post(`${CALL_API_URL}api/equipmentstatuschange`, data);
  }

  updateEquipmenttype(id, data) {
    return this.http.post(`${CALL_API_URL}api/updateEquipmenttype/` + id, data);
  }

  updateCategoryType(data) {
    return this.http.post(`${CALL_API_URL}api/updateCategorType`, data);
  }

  updateEquipment(data) {
    return this.http.post(`${CALL_API_URL}api/updateEquipment`, data);
  }

  // Project Services Started
  projectList(role) {
    if (role > 0) {
      return this.http.get(`${CALL_API_URL}api/projectList/` + role);
    } else {
      return this.http.get(`${CALL_API_URL}api/projectList`);
    }
  }

  projectView(id) {
    return this.http.get(`${CALL_API_URL}api/projectView/` + id);
  }
  projectViewwithJobiD(id) {
    return this.http.get(`${CALL_API_URL}api/projectViewwithJobiD/` + id);
  }
  projectView2(id) {
    return this.http.get(`${CALL_API_URL}api/projectView2/` + id);
  }

  listEstimation(id) {
    return this.http.get(`${CALL_API_URL}api/listEstimation/` + id);
  }

  addTask(data) {
    return this.http.post(`${CALL_API_URL}api/addTask`, data);
  }
  purchaseOrderRevision(data) {
    return this.http.post(`${CALL_API_URL}api/purchaseOrderRevision`, data);
  }
  purchaseOrderRevision2(data) {
    return this.http.post(`${CALL_API_URL}api/purchaseOrderRevision2`, data);
  }
  varpurchaseOrderRevision(data) {
    return this.http.post(`${CALL_API_URL}api/varpurchaseOrderRevision`, data);
  }
  sendOutLookEmail(data) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.http.post(`${CALL_API_URL}api/sendOutLookEmail`, data, {
      headers: headers,
    });
    // return this.http.post(`${CALL_API}sendOutLookEmail`,data);
  }
  sendPoOutLookEmail(data) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.http.post(`${CALL_API_URL}api/sendPoOutLookEmail`, data, {
      headers: headers,
    });
    // return this.http.post(`${CALL_API}sendOutLookEmail`,data);
  }
  sendRfiOutLookEmail(data) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.http.post(`${CALL_API_URL}api/SendRfiOutLookMail`, data, {
      headers: headers,
    });
    // return this.http.post(`${CALL_API}sendOutLookEmail`,data);
  }
  sendClaimOutLookEmail(data) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.http.post(`${CALL_API_URL}api/sendClaimOutLookEmail`, data, {
      headers: headers,
    });
    // return this.http.post(`${CALL_API}sendOutLookEmail`,data);
  }
  sendOutLookPCletter(data) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.http.post(`${CALL_API_URL}api/sendOutLookPCletter`, data, {
      headers: headers,
    });
    // return this.http.post(`${CALL_API}sendOutLookEmail`,data);
  }
  contactCustomer(id) {
    return this.http.get(`${CALL_API_URL}api/contactCustomer/` + id);
  }

  addnewRevision(data) {
    return this.http.post(`${CALL_API_URL}api/revision`, data);
  }

  getQuoteTemplateBasicDetails(revId) {
    return this.http.get(`${CALL_API_URL}api/get-quote-templates/` + revId);
  }

  getTemplates(id) {
    return this.http.get(`${CALL_API_URL}api/get-templates-with-id/` + id);
  }

  getQuoteTemplateList(revId) {
    return this.http.get(`${CALL_API_URL}api/get-quote-templates-list/` + revId);
  }

  getQuoteTemplateListByProjectId(projId) {
    return this.http.get(`${CALL_API_URL}api/get-quote-templates-list-project-id/` + projId);
  }

  getQuoteTemplate(id) {
    return this.http.get(`${CALL_API_URL}api/get-quote-template/` + id);
  }

  saveQuoteEmailTemplate(data) {
    return this.http.post(`${CALL_API_URL}api/save-quote-template`, data);
  }

  getTasklist(id) {
    return this.http.get(`${CALL_API_URL}api/getTasklist/` + id);
  }
  getTasklistbyproject(id) {
    return this.http.get(`${CALL_API_URL}api/getTasklistbyproject/` + id);
  }
  getUserMessages(data) {
    return this.http.post(`${CALL_API_URL}api/getUserMessages`, data);
  }
  getUserMessagesbyProject(data) {
    return this.http.post(`${CALL_API_URL}api/getUserMessagesbyProject`, data);
  }

  approveProjectEstimation(data) {
    return this.http.post(`${CALL_API_URL}api/approveEstimation`, data);
  }

  moveToLost(data) {
    return this.http.post(`${CALL_API_URL}api/moveToLost`, data);
  }

  viewSummeryList(id) {
    return this.http.get(`${CALL_API_URL}api/viewSummeryList/` + id);
  }

  downloadPdf(myFormData: FormData) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append(
      'Access-Control-Allow-Methods',
      'GET, POST, OPTIONS, PUT, PATCH, DELETE'
    );
    headers.append(
      'Access-Control-Allow-Headers',
      'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers,X-Access-Token,XKey,Authorization'
    );
    return this.http.post(`${CALL_API_URL}api/downloadPdf`, myFormData, {
      headers: headers,
      responseType: 'blob',
    });
  }
  downloadCPdf(myFormData: FormData) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append(
      'Access-Control-Allow-Methods',
      'GET, POST, OPTIONS, PUT, PATCH, DELETE'
    );
    headers.append(
      'Access-Control-Allow-Headers',
      'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers,X-Access-Token,XKey,Authorization'
    );
    return this.http.post(`${CALL_API_URL}api/downloadCPdf`, myFormData, {
      headers: headers,
      responseType: 'blob',
    });
  }

  customerViewSummary(id) {
    return this.http.get(`${CALL_API_URL}api/customerViewSummary/` + id);
  }

  viewSummaryCustomer(data) {
    return this.http.post(`${CALL_API_URL}api/viewSummaryCustomer`, data);
  }

  convertToJob(id) {
    return this.http.get(`${CALL_API_URL}api/convertToJob/` + id);
  }

  getActiveProjectManagers() {
    return this.http.get(`${CALL_API_URL}api/getActiveProjectManagers`);
  }

  getActiveSiteManagers() {
    return this.http.get(`${CALL_API_URL}api/getActiveSiteManagers`);
  }

  getJobId() {
    return this.http.get(`${CALL_API_URL}api/getJobId`);
  }

  storeJobs(data) {
    return this.http.post(`${CALL_API_URL}api/storeJobs`, data);
  }

  editCustomerId(id) {
    return this.http.get(`${CALL_API_URL}api/editCustomerId/` + id);
  }

  viewJobProjectListing(id) {
    return this.http.get(`${CALL_API_URL}api/viewJobProjectListing/` + id);
  }
  claimJobDetails(id) {
    return this.http.get(`${CALL_API_URL}api/claimJobDetails/` + id);
  }
  claimProgressLoad(id) {
    return this.http.get(`${CALL_API_URL}api/claimProgressLoad/` + id);
  }
  addClaimProgress(data) {
    return this.http.post(`${CALL_API_URL}api/addClaimProgress`, data);
  }
  claimbreakJobDetails(id) {
    return this.http.get(`${CALL_API_URL}api/claimbreakJobDetails/` + id);
  }
  addProjectClaimDuration(data) {
    return this.http.post(`${CALL_API_URL}api/apCd`, data);
  }
  addClaimPayment(data) {
    return this.http.post(`${CALL_API_URL}api/aClaimPay`, data);
  }
  updateClaimPrice(data) {
    return this.http.post(`${CALL_API_URL}api/uClaimPrice`, data);
  }
  claimPriceLog(data) {
    return this.http.post(`${CALL_API_URL}api/getclaimPriceLog`, data);
  }
  viewJobSummary(id) {
    return this.http.get(`${CALL_API_URL}api/viewJobSummary/` + id);
  }
  addJobValues(data) {
    return this.http.post(`${CALL_API_URL}api/addJobValues`, data);
  }
  projectCustomerSave(data) {
    return this.http.post(`${CALL_API_URL}api/projectCustomerSave`, data);
  }

  saveContactProjectCustomer(data) {
    return this.http.post(
      `${CALL_API_URL}api/saveContactProjectCustomer`,
      data
    );
  }

  changeEstimationStatus(data) {
    return this.http.post(`${CALL_API_URL}api/changeEstimationStatus`, data);
  }

  generateSupplier(data) {
    return this.http.post(`${CALL_API_URL}api/generateSupplier`, data);
  }

  getorderList(id) {
    return this.http.get(`${CALL_API_URL}api/getorderList/` + id);
  }
  getoRfiList(id) {
    return this.http.get(`${CALL_API_URL}api/getoRfiList/` + id);
  }
  getoRfiListByCustomerid(id) {
    return this.http.get(`${CALL_API_URL}api/get-rfi-list-by-customer-id/` + id);
  }
  getoPcList(id) {
    return this.http.get(`${CALL_API_URL}api/getoPcList/` + id);
  }
  getoPcListByCustomerId(id) {
    return this.http.get(`${CALL_API_URL}api/get-pc-list-by-customer-id/` + id);
  }
  getoFinalLetterList(id) {
    return this.http.get(`${CALL_API_URL}api/getoFinalLetterList/` + id);
  }
  getoFinalLetterListByCustomerid(id) {
    return this.http.get(`${CALL_API_URL}api/get-final-letter-list-by-customer-id/` + id);
  }
  fetchRfi(data) {
    return this.http.post(`${CALL_API_URL}api/fetchRfi`, data);
  }
  addRfi(data) {
    return this.http.post(`${CALL_API_URL}api/addRfi`, data);
  }
  jobSupplierDet(id) {
    return this.http.get(`${CALL_API_URL}api/jobSupplierDet/` + id);
  }

  purchOrderDet(id) {
    return this.http.get(`${CALL_API_URL}api/purchaseorderdet/` + id);
  }

  getAppInfo() {
    return this.http.get(`${CALL_API_URL}api/compInfo`);
  }
  updateCompnayForm(data) {
    return this.http.post(`${CALL_API_URL}api/compInfo`, data);
  }
  jobSupIdView(id) {
    return this.http.get(`${CALL_API_URL}api/jobSupIdView/` + id);
  }

  purchOrderIdView(id) {
    return this.http.get(`${CALL_API_URL}api/purchaseorder/` + id);
  }

  varjobSupIdView(id) {
    return this.http.get(`${CALL_API_URL}api/varjobSupIdView/` + id);
  }
  updatePurchase(data) {
    return this.http.post(`${CALL_API_URL}api/updatePurchase`, data);
  }
  updatePurchase2(data) {
    return this.http.post(`${CALL_API_URL}api/updatepurchaseorder`, data);
  }
  fetchLocationEquip(data) {
    return this.http.post(`${CALL_API_URL}api/fetchLocationEquip`, data);
  }
  varfetchLocationEquip(data) {
    return this.http.post(`${CALL_API_URL}api/varfetchLocationEquip`, data);
  }
  updateEquipPrice(data) {
    return this.http.post(`${CALL_API_URL}api/updateEquipPrice`, data);
  }
  updatePurchaseOrderRate(data) {
    return this.http.post(`${CALL_API_URL}api/update-purchase-order-rate`, data);
  }
  poComplete(id) {
    return this.http.get(`${CALL_API_URL}api/poComplete/` + id);
  }
  varpoComplete(id) {
    return this.http.get(`${CALL_API_URL}api/varpoComplete/` + id);
  }
  downloadPoPdf(myFormData: FormData) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append(
      'Access-Control-Allow-Methods',
      'GET, POST, OPTIONS, PUT, PATCH, DELETE'
    );
    headers.append(
      'Access-Control-Allow-Headers',
      'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers,X-Access-Token,XKey,Authorization'
    );
    return this.http.post(`${CALL_API_URL}api/downloadPoPdf`, myFormData, {
      headers: headers,
      responseType: 'blob',
    });
  }
  downloadPoPdf2(myFormData: FormData) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append(
      'Access-Control-Allow-Methods',
      'GET, POST, OPTIONS, PUT, PATCH, DELETE'
    );
    headers.append(
      'Access-Control-Allow-Headers',
      'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers,X-Access-Token,XKey,Authorization'
    );
    return this.http.post(`${CALL_API_URL}api/downloadPoPdf2`, myFormData, {
      headers: headers,
      responseType: 'blob',
    });
  }
  downloadRfiPdf(myFormData: FormData) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append(
      'Access-Control-Allow-Methods',
      'GET, POST, OPTIONS, PUT, PATCH, DELETE'
    );
    headers.append(
      'Access-Control-Allow-Headers',
      'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers,X-Access-Token,XKey,Authorization'
    );
    return this.http.post(`${CALL_API_URL}api/downloadRfiPdf`, myFormData, {
      headers: headers,
      responseType: 'blob',
    });
  }
  downloadclPdf(myFormData: FormData) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append(
      'Access-Control-Allow-Methods',
      'GET, POST, OPTIONS, PUT, PATCH, DELETE'
    );
    headers.append(
      'Access-Control-Allow-Headers',
      'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers,X-Access-Token,XKey,Authorization'
    );
    return this.http.post(`${CALL_API_URL}api/downloadclPdf`, myFormData, {
      headers: headers,
      responseType: 'blob',
    });
  }
  uploadPdf(myFormData: FormData) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.http.post(`${CALL_API_URL}api/uploadPdf`, myFormData, {
      headers: headers,
    });
  }

  uploadPdf2(myFormData: FormData) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.http.post(`${CALL_API_URL}api/uploadPdf2`, myFormData, {
      headers: headers,
    });
  }

  uploadPdf3(myFormData: FormData) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.http.post(`${CALL_API_URL}api/uploadPdf3`, myFormData, {
      headers: headers,
    });
  }

  unHideEquipments(data) {
    return this.http.post(`${CALL_API_URL}api/unHideEquipments`, data);
  }

  claimBreakDownload(data) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append(
      'Access-Control-Allow-Methods',
      'GET, POST, OPTIONS, PUT, PATCH, DELETE'
    );
    headers.append(
      'Access-Control-Allow-Headers',
      'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers,X-Access-Token,XKey,Authorization'
    );
    return this.http.post(`${CALL_API_URL}api/claimBreakDownload`, data, {
      headers: headers,
      responseType: 'blob',
    });
  }

  saveEmailTemplate(data) {
    return this.http.post(`${CALL_API_URL}api/email-templates`, data);
  }

  getEmailTemplates() {
    return this.http.get(`${CALL_API_URL}api/email-templates`);
  }

  getCommonEmailTemplates() {
    return this.http.get(`${CALL_API_URL}api/get-templates/common-templates`);
  }

  getTemplateTypes() {
    return this.http.get(`${CALL_API_URL}api/email-templates/create`);
  }

  getEmailTemplates2(id) {
    return this.http.get(`${CALL_API_URL}api/get-all-email-templates/` + id);
  }

  getTemplate(id) {
    return this.http.get(`${CALL_API_URL}api/email-templates/` + id + '/edit');
  }

  copyTemplate(id) {
    return this.http.get(`${CALL_API_URL}api/email-templates/` + id);
  }

  deleteTemplate(id) {
    return this.http.delete(`${CALL_API_URL}api/email-templates/` + id);
  }

  saveProjectEmailTemplate(data, project_id) {
    return this.http.post(`${CALL_API_URL}api/projects/` + project_id + `/project-email-templates`, data);
  }

  getProjectEmailTemplates(project_id) {
    return this.http.get(`${CALL_API_URL}api/projects/` + project_id + `/project-email-templates`);
  }

  getProjectTemplate(project_id, id) {
    return this.http.get(`${CALL_API_URL}api/projects/` + project_id + `/project-email-templates/` + id + '/edit');
  }

  copyProjectTemplate(project_id, id) {
    return this.http.get(`${CALL_API_URL}api/projects/` + project_id + `/project-email-templates/` + id);
  }

  deleteProjectTemplate(project_id, id) {
    return this.http.delete(`${CALL_API_URL}api/projects/` + project_id + `/project-email-templates/` + id);
  }

  sendEmailTemplate(data) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    // console.log(data);
    return this.http.post(`${CALL_API_URL}api/send-email-template`, data, {
      headers: headers,
    });
  }

  sendEmailQuoteTemplate(data) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    // console.log(data);
    return this.http.post(`${CALL_API_URL}api/send-email-quote-template`, data, {
      headers: headers,
    });
  }

  sendEmailQuoteTemplate2(data) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    // console.log(data);
    return this.http.post(`${CALL_API_URL}api/send-direct-email`, data, {
      headers: headers,
    });
  }

  downloadPDF(project_id, id) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append(
      'Access-Control-Allow-Methods',
      'GET, POST, OPTIONS, PUT, PATCH, DELETE'
    );
    headers.append(
      'Access-Control-Allow-Headers',
      'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers,X-Access-Token,XKey,Authorization'
    );
    // return this.http.post(`${CALL_API_URL}api/downloadCPdf`, myFormData, {
    //   headers: headers,
    //   responseType: 'blob',
    // });
    return this.http.post(`${CALL_API_URL}api/download-email-pdf/` + project_id + `/` + id, null, {
      headers: headers,
      responseType: 'blob',
    });
  }

  downloadQuotePDF(id) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append(
      'Access-Control-Allow-Methods',
      'GET, POST, OPTIONS, PUT, PATCH, DELETE'
    );
    headers.append(
      'Access-Control-Allow-Headers',
      'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers,X-Access-Token,XKey,Authorization'
    );
    // return this.http.post(`${CALL_API_URL}api/downloadCPdf`, myFormData, {
    //   headers: headers,
    //   responseType: 'blob',
    // });
    return this.http.post(`${CALL_API_URL}api/download-quote-pdf/` + id, null, {
      headers: headers,
      responseType: 'blob',
    });
  }

  getTaskListByJobId(id) {
    return this.http.get(`${CALL_API_URL}api/get-task-list-by-job-id/` + id);
  }

  getCustomerTemplateEmailsByCustomerid(id) {
    return this.http.get(`${CALL_API_URL}api/get-customer-template-emails-by-customerid/` + id);
  }

  getQuoteTemplateEmailsByCustomerid(id) {
    return this.http.get(`${CALL_API_URL}api/get-quote-templates-list-customer-id/` + id);
  }

  getTaskListByProjectId(id) {
    return this.http.get(`${CALL_API_URL}api/get-task-list-by-project-id/` + id);
  }
  deleteSign(id) {
    return this.http.get(`${CALL_API_URL}api/deleteSign/` + id);
  }

  /* Sachu */
  deleteClaimPay(data: any) {
    return this.http.post(`${CALL_API_URL}api/deleteClaimPay`, data);
  }
}
