import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
// import { AuthService } from '../../shared/services/firebase/auth.service';
import { AuthService } from '../../shared/auth/auth.service';
import { AuthStateService } from '../../shared/auth/auth-state.service';
import { CookieService } from 'ngx-cookie-service';
import { TokenService } from '../../shared/auth/token.service';


type UserFields = 'email' | 'password';
type FormErrors = { [u in UserFields]: string };

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  submitted = false;
  password: string = '';
  c_password: string = '';
  toggle1: boolean = false;
  toggle2: boolean = false;
  toggle3: boolean = false;
  toggle4: boolean = false;

  changeType(input_field_password, num) {
    if (input_field_password.type == "password")
      input_field_password.type = "text";
    else
      input_field_password.type = "password";

    if (num == 1)
      this.toggle1 = !this.toggle1;
    else
      this.toggle2 = !this.toggle2;
  }


  public newUser = false;
  public signup: boolean;
  public user: firebase.User;
  public loginForm: FormGroup;
  public registraionForm: FormGroup;
  Email: any = null;
  Password: any = null;
  public remember: any = false;
  public isSubmit: boolean = false;


  public formErrors: FormErrors = {
    'email': '',
    'password': '',
  };

  public errorMessage: any;

  constructor(public authService: AuthService, private fb: FormBuilder,
    private route: ActivatedRoute, public router: Router, private authState: AuthStateService, private _coockieservice: CookieService, private token: TokenService) {

    if (_coockieservice.get('remember') !== undefined) {
      if (_coockieservice.get('remember') === 'YES') {
        this.Email = this._coockieservice.get('email');
        this.Password = this._coockieservice.get('password');
        this.remember = true;
      }
    }



    this.loginForm = fb.group({
      email: [this.Email, [Validators.required, Validators.email]],
      password: [this.Password, Validators.required],
      remember: [this.remember],
    });
  }


  ngOnInit() {
  }

  public toggle() {
    this.signup = !this.signup;
  }

  login(data: any) {
    this.isSubmit = true;
    if (this.loginForm.valid) {
      this.onSubmit();
    }
  }
  onSubmit() {
    let userdetails = null;
    this.authService.signin(this.loginForm.value).subscribe(
      result => {
        if (result) {
          this.setLocalstorage(result, true);
          userdetails = result.user;
          this.responseHandler(result);
        } else {
          this.setLocalstorage(null, false);
          userdetails = null;
        }
       
      },
      error => {
        if (error.error.type == 1) {
          let errmsg = (error.error.error) ?? 'Invalid mail id.';
          this.loginForm.controls.email.setErrors({ 'backenderrors': true, 'errmsg': errmsg },);
        } else if (error.error.type == 2) {
          let errmsg = (error.error.error) ?? 'Invalid password.';
          this.loginForm.controls.password.setErrors({ 'backenderrors': true, 'errmsg': errmsg },);
        }
        this.setLocalstorage(null, false);

      }, () => {
      this.authState.setAuthState(true);
      if (this.loginForm.value.remember === true) {
        this._coockieservice.set('remember', 'YES');
        this._coockieservice.set('email', this.loginForm.value.email);
        this._coockieservice.set('password', this.loginForm.value.password);
      } else {
        this._coockieservice.set('remember', 'NO');
        this._coockieservice.set('email', null);
        this._coockieservice.set('password', null);
      }
      this.loginForm.reset();
      if (userdetails != null) {
        this.isSubmit = false;
        this.router.navigate(['total-summary']);
      }

    });
  }
  responseHandler(data) {
    this.token.handleData(data.access_token);
    localStorage.setItem('expires_in', (data?.expires_in)??0);
  }

  setLocalstorage(result: any, isValid = false) {
    if (isValid) {
      localStorage.setItem('airmechuser', JSON.stringify(result.user));
      localStorage.setItem('saved', new Date().toString());
      localStorage.setItem('airmechloggeddate', new Date().toString());
    }
    else {
      localStorage.setItem('airmechuser', null);
      localStorage.setItem('saved', null);
      localStorage.setItem('airmechloggeddate', new Date().toString());
    }
  }
}
