import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { AuthService } from '../services/firebase/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  
  constructor(public authService: AuthService,private cookiesService : CookieService,
    public router: Router) { }

  canActivate(next: ActivatedRouteSnapshot,
      state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // Guard for user is login or not
    const rememberMe = this.cookiesService.get('remember');
    // Check user is in session

    let user = JSON.parse(localStorage.getItem('airmechuser'));
    if (!user || user === null) {
      this.router.navigate(['/auth/login']);
      return true
    }
    else if (user) {
      if (!Object.keys(user).length) {
        this.router.navigate(['/auth/login']);
        return true
      }
    }

    // check token expire    
    let currentdate = new Date();
    let loggedDate:any = localStorage.getItem('loggeddate');   
    if(loggedDate!=null){
      loggedDate = new Date(loggedDate);
      let backendexpires =  (localStorage.getItem('expires_in'))??0;
      backendexpires = isNaN(+backendexpires) ? 0 : Number(backendexpires);
      backendexpires = backendexpires/60;
      backendexpires =  (backendexpires>0)?backendexpires-24:0;
      const backenndExpiresHrs = (backendexpires>0)?backendexpires:24*10;
      var timeDiff = (currentdate.getTime() - loggedDate.getTime()) / 1000;
      timeDiff /= (60 * 60);
      const loggedhours =  Math.abs(Math.round(timeDiff));
      if(backenndExpiresHrs <= loggedhours){
        localStorage.clear();
        this.cookiesService.set('remember',null); 
        this.cookiesService.set('email',null); 
        this.cookiesService.set('password',null); 
        this.router.navigate(['auth/login']);
        return false;
      }else{
        localStorage.setItem('loggeddate', new Date().toString());  
      }
    }


    // check max online hrs
    let saved = localStorage.getItem('saved');
    const hoursactive = 10;    
    if(saved!=null){      
      let saveddate = new Date(saved);
      var diff =(currentdate.getTime() - saveddate.getTime()) / 1000;
      diff /= (60 * 60);     
      const hours =  Math.abs(Math.round(diff));
      if(hoursactive<=hours && rememberMe === 'NO'){
        localStorage.clear();
        this.router.navigate(['auth/login']);
        return true
      }else{
        localStorage.setItem('saved', new Date().toString());        
      }
    }else{
      localStorage.setItem('saved', new Date().toString());
    }
    let dataroles = (next?.data)?next.data.roles:[];  
    console.log(dataroles,'dataroles');
    if(dataroles){
      let roleaccess = next.data.roles.some( ai => user.role===ai);
      if(roleaccess != true){
        this.router.navigate(['/project-list']);
        return true
      }
    } 
    return true
  }
  
}
