<!-- page-wrapper Start-->
<div [attr.main-theme-layout]="layout.config.settings.layout_type" 
     [ngClass]="layout.config.color.mix_background_layout">
  <div class="page-wrapper" 
       id="canvas-bookmark"
       [ngClass]="layout.config.settings.sidebar.type">
    <app-header></app-header>
    <!-- Page Body Start-->
    <div class="page-body-wrapper"
         [ngClass]="layout.config.settings.sidebar.body_type">
      <header class="main-nav"
              [attr.sidebar-layout]="layout.config.settings.sidebar_setting"
              [class.close_icon]="navServices.collapseSidebar">
        <app-sidebar></app-sidebar>
      </header>
      <div class="page-body">
        <main [@fadeInAnimation]="getRouterOutletState(o)">
          <router-outlet #o="outlet"></router-outlet>
        </main>
      </div>
      <!-- footer start-->
      <footer class="footer">
        <app-footer></app-footer>
      </footer>
      <!-- footer End-->
    </div>
    <!-- Page Body End-->
  </div>
</div>
<app-customizer></app-customizer>
<!-- page-wrapper End-->