import { Injectable, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject, fromEvent } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';

// Menu
export interface Menu {
	headTitle1?: string,
	headTitle2?: string,
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
	visible?:number[];
	relatedPath?:any[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService implements OnDestroy {

	private unsubscriber: Subject<any> = new Subject();
	public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

	public megaMenu: boolean = false;
	public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;
	public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;
	public fullScreen: boolean = false;

	constructor(private router: Router) {
		this.setScreenWidth(window.innerWidth);
		fromEvent(window, 'resize').pipe(
			debounceTime(1000),
			takeUntil(this.unsubscriber)
		).subscribe((evt: any) => {
			this.setScreenWidth(evt.target.innerWidth);
			if (evt.target.innerWidth < 991) {
				this.collapseSidebar = true;
				this.megaMenu = false;
			}
			if (evt.target.innerWidth < 1199) {
				this.megaMenuColapse = true;
			}
		});
		if (window.innerWidth < 991) { // Detect Route change sidebar close
			this.router.events.subscribe(event => {
				this.collapseSidebar = true;
				this.megaMenu = false;
			});
		}
	}

	ngOnDestroy() {
		this.unsubscriber.next();
		this.unsubscriber.complete();
	}

	private setScreenWidth(width: number): void {
		this.screenWidth.next(width);
	}

	MENUITEMS: Menu[] = [
		{ path: '/total-summary', title: 'Dashboard', icon: 'airplay', type: 'link', active: false, visible:[0,2,6],relatedPath:[]},
		{ path: '/users-list', title: 'Users ', icon: 'user', type: 'link', active: false, visible:[0,2,6],},
		{ path: '/project-list', title: 'Projects ', icon: 'align-justify', type: 'link', active: false, },
		{ path: '/customer-list', title: 'Customers', icon: 'users', type: 'link', active: false, visible:[0,1,2,3,4,6],relatedPath:[]},
		{ path: '/suppliers-list', title: 'Suppliers', icon: 'package', type: 'link', active: false, visible:[0,1,2,3,4,6],relatedPath:[]},
		{ path: '/subcontractors-list', title: 'Subcontractors', icon: 'layers', type: 'link', active: false, visible:[0,1,2,3,4,6],relatedPath:[]},
		{ path: '/misc-list', title: 'Miscellaneous', icon: 'command', type: 'link', active: false, visible:[0,1,2,3,4,6],relatedPath:[]},
		{
			title: 'Settings', icon: 'settings', type: 'sub', active: false, children: [
				{ path:'/profile-settings', title:'Profile', type: 'link', },
				{ path:'/category-settings', title:'Category', type: 'link', visible:[0,1,2,3,4,6],relatedPath:[]},
				{ path:'/type-settings', title:'Category Type', type: 'link', visible:[0,1,2,3,4,6],relatedPath:[]},
				{ path:'/equipment-settings', title:'Equipment', type: 'link', visible:[0,1,2,3,4,6],relatedPath:[]},
				{ path:'/company-settings', title:'Company settings', type: 'link', visible:[0,1,2,3,4,6],relatedPath:[]},
				{ path:'/email-template', title:'Templates', type: 'link', visible:[0,1,2,3,4,6],relatedPath:[]},
				// { path:'/quote-template', title:'Quote template', type: 'link', visible:[0,1,2,3,4,6],relatedPath:[]},

			]
		},
		// { path: '/view-cms', title: 'CMS', icon: 'users', type: 'link', active: false, },


	];

	MEGAMENUITEMS: Menu[] = [
	];

	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
	megaItems = new BehaviorSubject<Menu[]>(this.MEGAMENUITEMS);

}
