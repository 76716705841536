<main class="d-flex align-items-center min-vh-100 py-3 py-md-0">
  <div class="container">
    <div class="col-lg-6 offset-lg-3">
      <div class="card login-card">
        <div class="row no-gutters">
          <!-- <div class="col-md-5">
<img src="assets/images/Backgrounds/bhmclogin.png" alt="login" class="login-card-img">
</div> -->
          <div class="col-lg-12">
            <div class="card-body">
              <form class="theme-forms theme-form" [formGroup]="forgotForm" (ngSubmit)="onForgotFormSubmit()">
                <div class="col-sm-10 offset-sm-1">
                  <div class="brand-wrapper text-center">
                    <img src="assets/images/logo/logo.png" alt="logo" class="logo" />
                  </div>

                  <h6 class="text-center login-card-description">
                    FORGOT PASSWORD
                  </h6>
                  <p class="text-center f-11 login-card-description">
                    Please type your registered email address to reset your
                    password in the below text box
                  </p>
                  <div class="form-group">
                    <label for="email" class="control-label">Email address</label>
                    <input type="email" name="email" id="email" class="form-control mb-1" formControlName="email"
                      placeholder="Email address" placeholder="Email address" />

                    <div *ngIf="
forgotForm.controls.email.errors?.required && formSubmit
" class="text text-danger mt-1">
                      Email address should not be blank.
                    </div>
                    <div *ngIf="
forgotForm.controls.email.errors?.email && formSubmit
" class="text text-danger mt-1">
                      Invalid email format.
                    </div>
                    <div *ngIf="
formSubmit &&
forgotForm.controls.email.errors?.backenderrors
" class="text-danger">
                      {{
                      forgotForm.controls.email.errors.errmsg
                      ? forgotForm.controls.email.errors.errmsg
                      : "Email address is incorrect."
                      }}
                    </div>
                  </div>

                  <div class="">
                    <div class="text-center">
                      <button class="btn btn-pill login-btn mb-4" type="submit">
                        Reset password
                      </button>
                    </div>
                  </div>
                  <a routerLink="/auth/login" class="forgot-password-link">Back to login</a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>